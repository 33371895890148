.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
  z-index: -10;
}

.background-bg {
  position: absolute;
  z-index: -1;
}

.background-bg-img {
  width: 100%;
  height: 100%;
}

.foreground {
  position: relative;
}

.english-title {
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  color: #f20a0a;
}

.title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.content {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #0d0e15;
}

.items {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.item {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d0e15;
}

.item:first-of-type {
  background-color: #f20a0a;
  color: #ffffff;
}

.item-separator {
  width: 1px;
  height: 115px;
  background-color: #f7f7f7;
}

.item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-icon-brands {
  background-image: url("../../assets/brand_icon@2x.png");
}

.item-icon-maintenance {
  background-image: url("../../assets/maintenance_icon@2x.png");
}

.item-icon-sellerRate {
  background-image: url("../../assets/sold_icon@2x.png");
}
.item-icon-environmentalProtection {
  background-image: url("../../assets/epro_icon@2x.png");
}

.item-value {
  font-family: fonteditor;
}

.item-label-container {
  overflow: hidden;
}

.item-label {
  font-family: PingFangSC-Light, PingFang SC;
  text-align: center;
}

.is-marquee {
  text-align: left;
  white-space: nowrap;
  display: inline-block;
  animation: marqueeAnim 7s linear 0s infinite;
}

@keyframes marqueeAnim {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1440px) {
  .container {
    min-height: 617px;
  }
  .background-bg {
    width: 548px;
    height: 686px;
    right: calc(50% - 650px);
    bottom: calc(50% - 398px);
  }

  .foreground {
    min-width: 1024px;
    min-height: 525px;
    margin-top: 92px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 8px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .content {
    margin-top: 24px;
    width: 500px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .items {
    margin-top: 51px;
    height: 195px;
  }

  .item {
    color: #0d0e15;
  }

  .item-icon {
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
    margin-bottom: 24px;
  }

  .item-value {
    height: 34px;
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 24px;
  }

  .item-label-container {
    width: 200px;
    height: 24px;
    line-height: 24px;
  }

  .item-label {
    height: 24px;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }
}

@media screen and (max-width: 1440px) {
  .background-bg {
    width: 444px;
    height: 555px;
    right: calc(50% - 624px);
    bottom: calc(50% - 327px);
  }

  .foreground {
    min-width: 1022px;
    min-height: 461px;
    margin-top: 45px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 8px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .content {
    margin-top: 24px;
    width: 555px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .items {
    margin-top: 36px;
    height: 170px;
  }

  .item {
    color: #0d0e15;
  }

  .item-icon {
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
    margin-bottom: 20px;
  }

  .item-value {
    height: 34px;
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 14px;
  }

  .item-label-container {
    width: 200px;
    height: 15px;
    line-height: 15px;
  }

  .item-label {
    height: 15px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
}

@media screen and (max-width: 1280px) {
  .background-bg {
    width: 352px;
    height: 440px;
    right: calc(50% - 529px);
    top: calc(50% - 210px);
  }

  .foreground {
    min-width: 880px;
    min-height: 367px;
    margin-top: 53px;
  }

  .english-title {
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
  }

  .title {
    margin-top: 10px;
    height: 34px;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }

  .content {
    margin-top: 12px;
    width: 556px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  .items {
    margin-top: 28px;
    height: 117px;
  }

  .item {
    color: #0d0e15;
  }

  .item-icon {
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
    margin-bottom: 13px;
  }

  .item-value {
    height: 21px;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .item-label-container {
    width: 180px;
    height: 12px;
    line-height: 12px;
  }

  .item-label {
    width: 180px;
    height: 12px;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
}

@primary-color: #1DA57A;