.slide-container {
  width: 100vw;
  height: 100vh;
  min-width: 1280px;
  min-height: 570px;
  position: relative;
}

.foreground {
  position: absolute;
  width: 100%;
  height: 100%;
}

.logo-container {
  position: absolute;
  transform: translateX(-50%);
}

.logo-img {
  width: 100%;
  height: 100%;
}

.nav {
  display: block;
  position: fixed;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 1;
}

.nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &.is-active {
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #0d0e15;
  }
}

.nav-active-icon {
  background-color: #0d0e15;
}

.nav-label {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

.nav-content {
  width: 100%;
  height: 100%;
}

.nav-button-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.nav-button-img {
  width: 100%;
  height: 100%;
}

.dark-page {
  .nav {
    .nav-item {
      cursor: pointer;
      .nav-active-icon {
        background-color: #ffffff;
      }
      .nav-label {
        color: #ffffff;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .logo-container {
    width: 180px;
    height: 60px;
    top: 77px;
    left: 50%;
  }

  .nav {
    left: 8px;
    z-index: 10;
  }

  .nav-item {
    margin-top: 20px;
    height: 12px;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    &.is-active {
      height: 14px;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      transform: translateX(-16px);
    }
  }

  .nav-active-icon {
    width: 16px;
    height: 1px;
    margin-right: 11px;
  }

  .nav-button-container {
    bottom: 48px;
  }

  .nav-button {
    width: 23px;
    height: 23px;
    z-index: 10;
  }
}

@media screen and (max-width: 1440px) {
  .logo-container {
    width: 160px;
    height: 54px;
    top: 48px;
    left: 50%;
  }

  .nav {
    left: 8px;
  }

  .nav-item {
    margin-top: 20px;
    height: 12px;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    &.is-active {
      height: 14px;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      transform: translateX(-16px);
    }
  }

  .nav-active-icon {
    width: 16px;
    height: 1px;
    margin-right: 11px;
  }

  .nav-button-container {
    bottom: 28px;
  }

  .nav-button {
    width: 23px;
    height: 23px;
  }
}

@media screen and (max-width: 1280px) {
  .logo-container {
    width: 126px;
    height: 43px;
    top: 45px;
    left: 50%;
  }

  .nav {
    left: -8px;
  }

  .nav-item {
    margin-top: 20px;
    height: 12px;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    &.is-active {
      height: 14px;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      transform: translateX(-16px);
    }
  }

  .nav-active-icon {
    width: 16px;
    height: 1px;
    margin-right: 11px;
  }

  .nav-button-container {
    bottom: 29px;
  }

  .nav-button {
    width: 16px;
    height: 16px;
  }
}

@primary-color: #1DA57A;