.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
  z-index: -10;
}

.background-bg {
  position: absolute;
}

.background-bg-img {
  width: 100%;
  height: 100%;
}

.foreground {
  position: relative;
}

.background-bg-2 {
  position: absolute;
  z-index: 2;
}

.english-title {
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  color: #f20a0a;
}

.title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.items {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.item {
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-idx-img {
  width: 100%;
  height: 100%;
}

.item-title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.item-separator {
  border-bottom: 1px solid #0d0e15;
}

.item-content {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

@media screen and (min-width: 1440px) {
  .container {
    min-height: 610px;
  }

  .background-bg {
    width: 476px;
    height: 475px;
    right: calc(50% - 512px);
    top: calc(50% - 326px);
  }

  .foreground {
    min-width: 1024px;
    min-height: 531px;
  }

  .background-bg-2 {
    bottom: 265px;
    right: 50px;
    width: 428px;
    height: 266px;
  }

  .english-title {
    margin-top: 157px;
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 10px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .items {
    height: 294px;
    margin-top: 88px;
  }

  .item {
    width: 258px;
    height: 294px;
    border-radius: 40px 0px 0px 0px;
    padding: 0 28px;
  }

  .item-idx {
    margin-left: -28px;
    width: 80px;
    height: 80px;
  }

  .item-title {
    margin-top: 28px;
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .item-separator {
    margin-top: 14px;
    width: 33px;
  }

  .item-content {
    margin-top: 14px;
    height: 96px;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }
}

@media screen and (max-width: 1440px) {
  .background-bg {
    width: 380px;
    height: 380px;
    right: calc(50% - 512px);
    top: calc(50% - 284px);
  }

  .foreground {
    min-width: 1024px;
    min-height: 429px;
  }

  .background-bg-2 {
    bottom: 270px;
    right: 40px;
    width: 342px;
    height: 213px;
  }

  .english-title {
    margin-top: 69px;
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 8px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .items {
    height: 294px;
    margin-top: 72px;
  }

  .item {
    width: 258px;
    height: 294px;
    border-radius: 40px 0px 0px 0px;
    padding: 0 28px;
  }

  .item-idx {
    margin-left: -28px;
    width: 80px;
    height: 80px;
  }

  .item-title {
    margin-top: 28px;
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .item-separator {
    margin-top: 14px;
    width: 33px;
  }

  .item-content {
    margin-top: 14px;
    height: 96px;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }
}

@media screen and (max-width: 1280px) {
  .background-bg {
    top: calc(50% - 246px);
    right: calc(50% - 440px);
    width: 285px;
    height: 285px;
  }

  .foreground {
    min-width: 880px;
    min-height: 369px;
  }

  .background-bg-2 {
    bottom: 252px;
    right: 30px;
    width: 257px;
    height: 160px;
  }

  .english-title {
    margin-top: 55px;
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
  }

  .title {
    margin-top: 10px;
    height: 34px;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }

  .items {
    height: 279px;
    margin-top: 34px;
  }

  .item {
    width: 214px;
    height: 279px;
    border-radius: 40px 0px 0px 0px;
    padding: 0 28px;
  }

  .item-idx {
    margin-left: -28px;
    width: 80px;
    height: 80px;
  }

  .item-title {
    margin-top: 20px;
    width: 220px;
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .item-separator {
    margin-top: 12px;
    width: 33px;
  }

  .item-content {
    margin-top: 12px;
    width: 214px;
    height: 88px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
  }
}

@primary-color: #1DA57A;