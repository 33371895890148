.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: -10;
}

.background-bg {
  position: absolute;
}

.background-bg-img {
  width: 100%;
  height: 100%;
}

.background-footer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-span {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #aaaaaa;
  white-space: nowrap;
  a {
    // text-decoration:none;
    // color:#333;
  }
}

.foreground {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.left {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.english-title {
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  color: #f20a0a;
}

.title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.content {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.brand-news {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.column-title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.left-right-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-button {
  background-image: url("../../assets/left.png");
  background-color: transparent;
  &:active {
    background-image: url("../../assets/left_active.png");
  }
}

.right-button {
  background-image: url("../../assets/right.png");
  background-color: transparent;
  &:active {
    background-image: url("../../assets/right_active.png");
  }
}

.card-container {
  background: #f7f7f7;
  border: 1px solid #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.news-infos {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.news-title {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #0d0e15;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-content {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
  opacity: 0.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.news-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.news-source {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
  opacity: 0.5;
}

.news-link {
  background-image: url("../../assets/link_jump_icon.png");
}

.contract-us {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-container {
    align-items: center;
  }
}
.email-groups {
  flex-shrink: 0;
}
.email-groups {
  display: flex;
  flex-direction: column;
}

.email-item {
  display: flex;
  flex-direction: row;
}

.email-label {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

.email-value {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #0d0e15;
}

.qr-codes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.qr-code-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-title {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

@media screen and (min-width: 1440px) {
  .container {
    min-height: 578px;
  }

  .background-bg {
    width: 511px;
    height: 641px;
    top: calc(50% - 330px);
    left: calc(50% - 674px);
  }

  .background-footer {
    bottom: 16px;
    width: 100%;
    height: 16px;
    flex-direction: row;
  }

  .footer-span {
    display: inline;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
  }

  .foreground {
    min-width: 1058px;
    min-height: 506px;
    margin: 72px 34px 0 0;
  }

  .left {
    margin-top: 48px;
    width: 324px;
    padding: 40px 31px 40px 34px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 8px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .content {
    margin-top: 24px;
    width: 324px;
    min-height: 263px;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  .right {
    width: 530px;
  }

  .title-container {
    width: 100%;
    height: 36px;
  }

  .column-title {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .left-right-buttons {
    width: 84px;
  }

  .left-right-button {
    width: 36px;
    height: 36px;
  }

  .left-button {
    background-size: 36px 36px;
    border-width: 0px;
  }

  .right-button {
    background-size: 36px 36px;
    border-width: 0px;
  }

  .news-container {
    margin-top: 7px;
  }

  .card-container {
    margin-top: 5px;
    width: 490px;
    height: 78px;
    padding: 20px;
    border-radius: 8px;
  }

  .news-image-container {
    width: 120px;
    height: 78px;
  }

  .news-image {
    width: 120px;
    height: 78px;
  }

  .news-infos {
    padding-left: 13px;
  }

  .news-title {
    width: 345px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .news-content {
    margin-top: 8px;
    height: 28px;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .news-footer {
    margin-top: 11px;
  }

  .news-source {
    height: 14px;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .news-link {
    width: 12px;
    height: 9px;
    background-size: 12px 9px;
  }

  .contract-us {
    .card-container {
      height: 100px;
    }
  }

  .email-groups {
    margin-top: -12px;
  }

  .email-item {
    margin-top: 12px;
  }

  .email-label {
    height: 17px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
  }

  .email-value {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .qr-code-box {
    width: 104px;
  }

  .qr-code-picture {
    width: 93px;
    height: 93px;
  }

  .qr-code-title {
    height: 9px;
    font-size: 9px;
    font-weight: 300;
    line-height: 9px;
  }
}

@media screen and (max-width: 1440px) {
  .background-bg {
    width: 327px;
    height: 410px;
    top: calc(50% - 270px);
    left: calc(50% - 630px);
  }

  .background-footer {
    bottom: 16px;
    width: 100%;
    height: 16px;
    flex-direction: row;
  }

  .footer-span {
    display: inline;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .foreground {
    min-width: 1058px;
    min-height: 383px;
    margin: 59px 35px 0 0;
  }

  .left {
    margin-top: 0px;
    width: 366px;
    padding: 40px 34px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 8px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .content {
    margin-top: 24px;
    min-height: 212px;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  .right {
    width: 530px;
  }

  .title-container {
    width: 100%;
    height: 36px;
  }

  .column-title {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .left-right-buttons {
    width: 84px;
  }

  .left-right-button {
    width: 36px;
    height: 36px;
  }

  .left-button {
    background-size: 36px 36px;
    border-width: 0px;
  }

  .right-button {
    background-size: 36px 36px;
    border-width: 0px;
  }

  .news-container {
    margin-top: 7px;
  }

  .card-container {
    margin-top: 5px;
    width: 490px;
    height: 78px;
    padding: 20px;
    border-radius: 8px;
  }

  .news-image-container {
    width: 120px;
    height: 78px;
  }

  .news-image {
    width: 120px;
    height: 78px;
  }

  .news-infos {
    padding-left: 13px;
  }

  .news-title {
    width: 345px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .news-content {
    margin-top: 8px;
    height: 28px;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .news-footer {
    margin-top: 11px;
  }

  .news-source {
    height: 14px;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .news-link {
    width: 12px;
    height: 9px;
    background-size: 12px 9px;
  }

  .contract-us {
    .card-container {
      height: 100px;
    }
  }

  .email-groups {
    margin-top: -12px;
  }

  .email-item {
    margin-top: 12px;
  }

  .email-label {
    height: 17px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
  }

  .email-value {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .qr-code-box {
    width: 104px;
  }

  .qr-code-picture {
    width: 93px;
    height: 93px;
  }

  .qr-code-title {
    height: 9px;
    font-size: 9px;
    font-weight: 300;
    line-height: 9px;
  }
}

@media screen and (max-width: 1280px) {
  .background-bg {
    width: 400px;
    height: 500px;
    top: calc(50% - 255px);
    left: calc(50% - 541px);
  }

  .background-footer {
    bottom: 16px;
    width: 100%;
    height: 32px;
    flex-direction: column;
  }

  .footer-span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .foreground {
    min-width: 880px;
    min-height: 367px;
    margin: 11px 0 0 0;
  }

  .left {
    margin-top: 48px;
    width: 360px;
    padding: 28px 24px;
  }

  .english-title {
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
  }

  .title {
    margin-top: 10px;
    height: 34px;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }

  .content {
    margin-top: 12px;
    width: 360px;
    min-height: 159px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
  }

  .right {
    width: 424px;
  }

  .title-container {
    width: 100%;
    height: 36px;
  }

  .column-title {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .left-right-buttons {
    width: 84px;
  }

  .left-right-button {
    width: 36px;
    height: 36px;
  }

  .left-button {
    background-size: 36px 36px;
    border-width: 0px;
  }

  .right-button {
    background-size: 36px 36px;
    border-width: 0px;
  }

  .news-container {
    margin-top: 7px;
  }

  .card-container {
    margin-top: 5px;
    width: 384px;
    height: 78px;
    padding: 20px;
    border-radius: 8px;
  }

  .news-image-container {
    width: 120px;
    height: 78px;
  }

  .news-image {
    width: 120px;
    height: 78px;
  }

  .news-infos {
    padding-left: 13px;
  }

  .news-title {
    width: 252px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .news-content {
    margin-top: 8px;
    width: 252px;
    height: 28px;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .news-footer {
    margin-top: 11px;
  }

  .news-source {
    height: 14px;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .news-link {
    width: 12px;
    height: 9px;
    background-size: 12px 9px;
  }

  .contract-us {
    .card-container {
      height: 110px;
      width: 405px;
      padding: 15px 17px 12px 20px;
    }
  }

  .email-groups {
    margin-top: -12px;
  }

  .email-item {
    margin-top: 12px;
  }

  .email-label {
    height: 17px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
  }

  .email-value {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .qr-code-box {
    width: 104px;
  }

  .qr-code-picture {
    width: 93px;
    height: 93px;
  }

  .qr-code-title {
    height: 9px;
    font-size: 9px;
    font-weight: 300;
    line-height: 9px;
  }
}

@primary-color: #1DA57A;