.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background-image {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  background: url("../../assets/first_bg.png") no-repeat;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.background-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.foreground {
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.qr-code-container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-label {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #0d0e15;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.slogan-img {
  width: 100%;
  height: 100%;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.download-button {
  background: #f20a0a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-button-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download-button-label {
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
}

@media screen and (min-width: 1440px) {
  .background-icon {
    width: 84px;
    height: 84px;
    right: 40px;
  }

  .foreground {
    padding-bottom: 80px;
  }

  .left {
    width: 230px;
    height: 266px;
    margin-right: 24px;
  }
  .right {
    width: 230px;
    height: 266px;
    margin-left: 24px;
  }

  .qr-code-container {
    width: 202px;
    height: 238px;
    border-radius: 8px;
    padding: 14px;
  }
  .qr-code {
    width: 202px;
    height: 202px;
  }

  .qr-code-label {
    margin-top: 10px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .middle {
    width: 515px;
    height: 214px;
  }

  .slogan {
    width: 100%;
    height: 110px;
  }

  .button-container {
    width: 515px;
    height: 51px;
  }

  .download-button {
    width: 249px;
    height: 100%;
    border-radius: 10px;
  }

  .download-button-label-container {
    height: 20px;
  }

  .download-button-label-icon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  .download-button-label {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 1440px) {
  .background-icon {
    width: 64px;
    height: 64px;
    right: 40px;
  }

  .foreground {
    padding-bottom: 80px;
  }

  .left {
    width: 230px;
    height: 266px;
    margin-right: 24px;
  }
  .right {
    width: 230px;
    height: 266px;
    margin-left: 24px;
  }

  .qr-code-container {
    width: 202px;
    height: 238px;
    border-radius: 8px;
    padding: 14px;
  }
  .qr-code {
    width: 202px;
    height: 202px;
  }

  .qr-code-label {
    margin-top: 10px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .middle {
    width: 515px;
    height: 214px;
  }

  .slogan {
    width: 100%;
    height: 110px;
  }

  .button-container {
    width: 515px;
    height: 51px;
  }

  .download-button {
    width: 249px;
    height: 100%;
    border-radius: 10px;
  }

  .download-button-label-container {
    height: 20px;
  }

  .download-button-label-icon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  .download-button-label {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 1280px) {
  .background-icon {
    width: 64px;
    height: 64px;
    right: 24px;
  }

  .foreground {
    padding-bottom: 20px;
  }

  .left {
    width: 163px;
    height: 186px;
    margin-right: 17px;
  }
  .right {
    width: 163px;
    height: 186px;
    margin-left: 17px;
  }

  .qr-code-container {
    width: 142px;
    height: 167px;
    border-radius: 8px;
    padding: 10px;
  }
  .qr-code {
    width: 142px;
    height: 142px;
  }

  .qr-code-label {
    margin-top: 7px;
    height: 14px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .middle {
    width: 361px;
    height: 151px;
  }

  .slogan {
    width: 100%;
    height: 77px;
  }

  .button-container {
    width: 361px;
    height: 36px;
  }

  .download-button {
    width: 174px;
    height: 100%;
    border-radius: 10px;
  }

  .download-button-label-container {
    height: 20px;
  }

  .download-button-label-icon {
    width: 14px;
    height: 14px;
    margin-right: 11px;
  }

  .download-button-label {
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 1px;
  }
}

@primary-color: #1DA57A;