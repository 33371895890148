@1280: "../../assets/circle_1280.png";
@1440: "../../assets/1440.png";
@1920: "../../assets/1920.png";
.container {
  width: 100%;
  height: 100%;
}
.background {
  width: 1100px;
  height: 520px;
  background: url("@{1920}") center center/contain;
}
.foreground {
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.circular-container {
  position: relative;
}

.content-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.english-title {
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  color: #f20a0a;
}

.title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.content {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #0d0e15;
}

.image-container {
  position: absolute;
  float: left;
  z-index: -1;
}

.paths-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.paths {
  display: block;
  list-style-type: none;
}

.path {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

.path::marker {
  content: "";
}

.buyer-path {
  text-align: right;
  &:after {
    content: "";
    display: inline-block;
    background-image: url("../../assets/buyer_process_icon.png");
  }
}

.seller-path {
  text-align: left;
  &::before {
    content: "";
    display: inline-block;
    background-image: url("../../assets/seller_process_icon.png");
  }
}

.image {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1440px) {
  @container-width: 366px;
  @container-height: 450px;
  .background {
    width: 1100px;
    height: 520px;
    background: url("@{1920}") center center/contain;
  }
  .seller-container {
    left: 0px;
  }
  .buyer-container {
    left: 0px;
  }
  .circular-container {
    margin-top: 24px;
    min-width: @container-width;
    min-height: @container-height;
  }

  .content-container {
    width: 253px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    height: 40px;
    margin-top: 8px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .content {
    height: 48px;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .image-container {
    width: 240px;
    height: 240px;
  }

  .paths {
    margin-block-start: -18px;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }

  .path {
    margin-top: 18px;
    height: 18px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }

  .buyer-path {
    &:after {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background-size: 10px 10px;
    }
  }

  .seller-path {
    &::before {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      background-size: 10px 10px;
    }
  }

  .plum-container {
    // margin: 0 53px;
    min-width: 500px;
    width: 500px;
    height: 500px;
  }

  .buyer-container {
    .content-container {
      right: 0;
      top: 40px;
    }
    .image-container {
      left: 80px;
      bottom: 0;
    }
    .paths-container {
      right: 0;
      bottom: 22px;
    }
  }

  .seller-container {
    .content-container {
      left: 0;
      bottom: 35px;
    }
    .image-container {
      left: 10px;
      top: -30px;
    }
    .paths-container {
      left: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: 1440px) {
  @container-width: 366px;
  @container-height: 450px;
  .background {
    width: 1000px;
    height: 520px;
    background: url("@{1440}") center center/contain;
  }
  .seller-container {
    left: 50px;
  }
  .buyer-container {
    left: -50px;
  }
  .circular-container {
    margin-top: 24px;
    min-width: @container-width;
    min-height: @container-height;
  }

  .content-container {
    width: 253px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    height: 40px;
    margin-top: 8px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .content {
    height: 48px;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .image-container {
    width: 240px;
    height: 240px;
  }

  .paths {
    margin-block-start: -18px;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }

  .path {
    margin-top: 21px;
    height: 18px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }

  .buyer-path {
    &:after {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background-size: 10px 10px;
    }
  }

  .seller-path {
    &::before {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      background-size: 10px 10px;
    }
  }

  .plum-container {
    margin: 0 0px;
    min-width: 480px;
    width: 480px;
    height: 480px;
  }

  .buyer-container {
    .content-container {
      right: -20px;
      top: 40px;
    }
    .image-container {
      left: 110px;
      bottom: 30px;
    }
    .paths-container {
      right: 0;
      bottom: 22px;
    }
  }

  .seller-container {
    .content-container {
      left: -20px;
      bottom: 65px;
    }
    .image-container {
      right: 130px;
      top: -30px;
    }
    .paths-container {
      left: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  @container-width: 319px;
  @container-height: 351px;
  .background {
    width: 1000px;
    height: 520px;
    background: url("@{1280}") center center/contain;
  }
  .seller-container {
    left: 0px;
  }
  .buyer-container {
    left: 0px;
  }
  .circular-container {
    margin-top: 62px;
    min-width: @container-width;
    min-height: @container-height;
  }

  .content-container {
    width: 280px;
  }

  .english-title {
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
  }

  .title {
    margin-top: 10px;
    height: 34px;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }

  .content {
    margin-top: 12px;
    width: 280px;
    height: 44px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  .image-container {
    width: 194px;
    height: 194px;
  }

  .paths {
    margin-block-start: -8px;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }

  .path {
    margin-top: 14px;
    height: 18px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }

  .buyer-path {
    &:after {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background-size: 10px 10px;
    }
  }

  .seller-path {
    &::before {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      background-size: 10px 10px;
    }
  }

  .plum-container {
    margin: 0 0;
    min-width: 390px;
    width: 390px;
    height: 390px;
  }

  .buyer-container {
    .content-container {
      right: 10px;
      top: 20px;
    }
    .image-container {
      left: 10px;
      bottom: 0;
      bottom: 31px;
    }
    .paths-container {
      right: 0;
      bottom: 31px;
    }
  }

  .seller-container {
    .content-container {
      left: 30px;
      bottom: 70px;
    }
    .image-container {
      right: 70px;
      top: -64px;
    }
    .paths-container {
      left: 0;
      top: 0;
    }
  }
}

@primary-color: #1DA57A;