.container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f7f7f7;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.foreground {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.english-title {
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  color: #f20a0a;
}

.title {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.avatars {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.avatar-container {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  &:nth-child(3) {
    box-sizing: border-box;
  }
  &:nth-child(4) {
    box-sizing: border-box;
  }
}

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
}

.avatar-img {
  width: 100%;
  height: 100%;
}

.quote {
  z-index: 10;
}

.voice-container {
  background: #ffffff;
  align-content: center;
}

.voice-user-name {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #0d0e15;
}

.voice-content {
  font-family: PingFangSC-Light, PingFang SC;
  color: #0d0e15;
}

.voice-picture {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pictures {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1440px) {
  @foreground-width: 1010px;

  .foreground {
    width: @foreground-width;
    height: 535px;
  }

  .english-title {
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 10px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .avatars {
    margin-top: 54px;
    width: 100%;
  }

  .content-container {
    margin-top: -275px;
  }

  .avatar-container {
    width: @foreground-width * 0.5;
    padding: 0px 0px 30px 0px;
    &:nth-child(3) {
      padding-left: 36px;
    }
    &:nth-child(4) {
      padding-right: 36px;
    }
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f7f7f7;
    &.is-active {
      margin-top: -10px;
      width: 72px;
      height: 72px;
      .avatar-img {
        width: 68px;
        height: 68px;
      }
    }
  }

  .quote {
    width: 50px;
    height: 50px;
  }

  .voice-container {
    margin-top: -25px;
    width: 600px;
    height: 280px;
    border-radius: 80px 80px 80px 0px;
    padding-top: 44px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .voice-user-name {
    height: 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  }

  .voice-content {
    margin-top: 14px;
    width: 600px;
    font-size: 14px;
    font-weight: 300;
    line-height: 34px;
  }

  .voice-picture {
    margin-top: 42px;
    height: 180px;
  }

  .pictures {
    width: 385px;
  }

  .picture {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 1440px) {
  @foreground-width: 1010px;

  .foreground {
    width: @foreground-width;
    height: 492px;
  }

  .english-title {
    margin-top: 18px;
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .title {
    margin-top: 10px;
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .avatars {
    margin-top: 60px;
    width: 100%;
  }

  .content-container {
    margin-top: -325px;
  }

  .avatar-container {
    width: @foreground-width * 0.5;
    padding: 0px 0px 30px 0px;
    &:nth-child(3) {
      padding-left: 36px;
    }
    &:nth-child(4) {
      padding-right: 36px;
    }
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f7f7f7;
    &.is-active {
      margin-top: -10px;
      width: 72px;
      height: 72px;
      .avatar-img {
        width: 68px;
        height: 68px;
      }
    }
  }

  .quote {
    width: 50px;
    height: 50px;
  }

  .voice-container {
    margin-top: -25px;
    width: 600px;
    height: 189px;
    background: #ffffff;
    border-radius: 80px 80px 80px 0px;
    padding-top: 44px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .voice-user-name {
    height: 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  }

  .voice-content {
    margin-top: 15px;
    width: 600px;
    font-size: 14px;
    font-weight: 300;
    line-height: 32px;
  }

  .voice-picture {
    margin-top: 32px;
    height: 160px;
  }

  .pictures {
    width: 344px;
  }

  .picture {
    width: 160px;
    height: 160px;
  }
}

@media screen and (max-width: 1280px) {
  @foreground-width: 858px;

  .foreground {
    width: @foreground-width;
    height: 382px;
  }

  .english-title {
    margin-top: 34px;
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
  }

  .title {
    margin-top: 10px;
    height: 34px;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }

  .avatars {
    margin-top: 33px;
    width: 100%;
  }

  .content-container {
    margin-top: -260px;
  }

  .avatar-container {
    width: @foreground-width * 0.5;
    padding: 0px 0px 35px 0px;
    &:nth-child(3) {
      padding-left: 30px;
    }
    &:nth-child(4) {
      padding-right: 30px;
    }
  }

  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    &.is-active {
      margin-top: -10px;
      width: 63px;
      height: 63px;
      .avatar-img {
        width: 61px;
        height: 61px;
      }
    }
  }

  .quote {
    width: 42px;
    height: 42px;
  }

  .voice-container {
    margin-top: -25px;
    width: 500px;
    height: 166px;
    background: #ffffff;
    border-radius: 50px 50px 50px 0px;
    padding-top: 34px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .voice-user-name {
    height: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }

  .voice-content {
    margin-top: 12px;
    width: 510px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
  }

  .voice-picture {
    margin-top: 24px;
    height: 120px;
  }

  .pictures {
    width: 264px;
  }

  .picture {
    width: 120px;
    height: 120px;
  }
}

@primary-color: #1DA57A;